import React, {FC, useEffect} from "react";
import {MuiThemeProvider} from "@material-ui/core";
import CssBaseline from "@material-ui/core/es/CssBaseline/CssBaseline";
import {useTranslation} from 'react-i18next';
import {Helmet} from "react-helmet";
import * as firebase from "firebase/app";
import {subscribeAll} from "./State/GameState/Subscriptions";
import {Navigator} from "./Navigation/Navigator";
import {MaterialTheme} from "./Theme/MaterialTheme";
import {GameState} from "./State/GameState/Types";
import {getWholeState} from "./API/Storage";
import {updateDevelopmentMode, updateWholeGameAction} from "./State/GameState/Actions";
import {WEB_URL} from "./Config/Constants";

export const App: FC = () => {
  const {t} = useTranslation();

  useEffect(() => {
    let firebaseConfig = {
      apiKey: "AIzaSyDT03WJGiaNeZn3NJvVVkAc1nYpJ4ykol0",
      authDomain: "hello-world-a6bb0.firebaseapp.com",
      databaseURL: "https://hello-world-a6bb0.firebaseio.com",
      projectId: "hello-world-a6bb0",
      storageBucket: "hello-world-a6bb0.appspot.com",
      messagingSenderId: "682416459862",
      appId: "1:682416459862:web:039cf69eb3fe5d0e",
      measurementId: "G-BL0GG7E2ET"
    };

    if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    subscribeAll();
  }, []);

  const savedGame: GameState = getWholeState();
  if (savedGame) updateWholeGameAction(savedGame);

  // @ts-ignore
  updateDevelopmentMode(window.location.hostname !== WEB_URL)

  return (
      <MuiThemeProvider theme={MaterialTheme}>
        <div style={{height: 'inherit'}}>
          <Helmet>
            <title>Drunk Evolution: The funniest drinking game</title>
            <meta name="description" content={t('Home.more')}/>
          </Helmet>
          <CssBaseline/>
          <Navigator/>
        </div>
      </MuiThemeProvider>
  )
};

export default App;
