import {GlobalStyles, StyleSheet} from "../../Theme/GlobalStyles";
import {Theme} from "../../Theme/Theme";

export const styles: StyleSheet = {
  container: {
    ...GlobalStyles.containerDark,
  },
  containerPhone: {
    ...GlobalStyles.containerDark,
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: Theme.spaceBig,
  },
  mainRow: {
    marginBottom: 96,
    width: '100%'
  }
};