import React, {FC, useState} from "react";
import {Button} from "./Button";
import {Image} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {styles} from "./ButtonsStyles"
import "./../../Theme/CSSStyles.css";

const flameOn = require('../../Images/icons/flame-on.png');
const flameOff = require('../../Images/icons/flame-off.png');

type Props = {
  onToggle: () => void
  initialHot: boolean
};

export const ModeButton: FC<Props> = ({onToggle, initialHot}) => {
  const {t} = useTranslation();
  const [isHot, setIsHot] = useState(initialHot);

  return (
      <Button onPress={() => {setIsHot(!isHot); onToggle()}} transparent>
        <Image src={isHot ? flameOn : flameOff} style={styles.flame}/>
        <p className={isHot ? "hot-enabled" : "hot-disabled"}>{t('EtilicBattle.switchHot')}</p>
        <Image src={isHot ? flameOn : flameOff} style={styles.flame}/>
      </Button>
  )
};