import React, {FC} from 'react';
import './Sitemap.css';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import {useTranslation} from 'react-i18next';

const Sitemap: FC = (props: any) => {
    const {t} = useTranslation();

    return (
        <div className='container'>
            <Card className='mt-5'>
                <CardContent>
                    {/*Title*/}
                    <Typography variant='h5' className='text-center'>
                        {t('Sitemap.title')}
                    </Typography>

                    {/*Map*/}
                    <ul>
                        <li><a href="/">{t('Sitemap.home')}</a></li>
                        <li><a href="/players">{t('Sitemap.play')}</a></li>
                        <li><a href="/#faqs">{t('Sitemap.faqs')}</a></li>
                    </ul>
                </CardContent>
            </Card>
        </div>
    )
};

export default Sitemap;