import {removeNullChallenges} from "../Utils/ChallengesUtils";
import {Challenge, GameState} from "../State/GameState/Types";
import {selectWholeState} from "../State/GameState/Selectors";

enum Storage {
  SESSION = 'SESSION',
  LOCAL = 'LOCAL'
}

const etilicBattleKey = 'ETILIC_BATTLE';
const charactersKey = 'CHARACTERS';
const lastPowersKey = 'LAST_POWERS';
const lastSyncKey = 'LAST_SYNC';
const overAgeKey = 'OVER_AGE';
const startDialogKey = 'START_DIALOG';
const stateKey = 'STATE';

// METHODS TO STORE THE CACHE FROM THE SERVER
export const saveStartChallenges = (challenges: any) => {
  saveObject(etilicBattleKey, removeNullChallenges(challenges), Storage.LOCAL);
};
export const getStartChallenges = (lang): Challenge[] => {
  const challenges = getObject(etilicBattleKey, Storage.LOCAL);
  return challenges ? challenges[lang] : [];
};

export const saveCharacters = (characters: any) => {
  saveObject(charactersKey, characters, Storage.LOCAL);
};
export const getCharacters = (lang): Array<any> => {
  const characters = getObject(charactersKey, Storage.LOCAL);
  return characters ? characters[lang] : [];
};

export const saveLastPowers = (lastPowers: any) => {
  saveObject(lastPowersKey, lastPowers, Storage.LOCAL);
};
export const getLastPowers = (lang): string[] => {
  const powers = getObject(lastPowersKey, Storage.LOCAL);
  return powers ? powers[lang] : [];
};

export const saveLastSync = () => {
  const date = new Date().getTime();
  saveSingleValue(lastSyncKey, date, Storage.LOCAL);
};
export const getLastSyncDate = (): Date => {
  const res = getSingleValue(lastSyncKey, Storage.LOCAL);
  return new Date(res);
};

// METHODS TO STORE THE VISUAL ASPECTS
export const saveOverAge = () => {
  saveSingleValue(overAgeKey, 'ok', Storage.SESSION);
};
export const getOverAge = (): string => {
  return getSingleValue(overAgeKey, Storage.SESSION);
};

// METHODS TO STORE THE STATE
export const saveWholeState = (state: GameState) => {
  saveObject(stateKey, state, Storage.SESSION);
}
export const getWholeState = (): GameState => {
  const nonParsedGame: any = getObject(stateKey, Storage.SESSION);
  return nonParsedGame;
};

// METHODS CALLED INSIDE THIS FILE
const saveSingleValue = (key: string, value: any, type: Storage) => {
  try {
    if (type === Storage.LOCAL) {
      // @ts-ignore
      localStorage.setItem(key, value);
    } else {
      // @ts-ignore
      sessionStorage.setItem(key, value);
    }
  } catch (e) {
    console.error(`[Storage] Error saving ${key}.`, e);
  }
};

const saveObject = (key: string, value: any, type: Storage) => {
  try {
    if (type === Storage.LOCAL) {
      // @ts-ignore
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      // @ts-ignore
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  } catch (e) {
    console.error(`[Storage] Error saving ${key}.`, e);
  }
};

const getSingleValue = (key: string, type: Storage): string => {
  if (type === Storage.LOCAL) {
    // @ts-ignore
    return localStorage.getItem(key);
  } else {
    // @ts-ignore
    return sessionStorage.getItem(key);
  }
};

const getObject = (key: string, type: Storage): Object => {
  let result;
  if (type === Storage.LOCAL) {
    // @ts-ignore
    result = localStorage.getItem(key);
  } else {
    // @ts-ignore
    result = sessionStorage.getItem(key);
  }
  return JSON.parse(result)
};