import {GlobalStyles, StyleSheet} from "../../Theme/GlobalStyles";
import {Theme} from "../../Theme/Theme";

export const styles: StyleSheet = {
  container: {
    ...GlobalStyles.containerDark,
  },
  containerPhone: {
    ...GlobalStyles.containerDark,
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: Theme.spaceBig,
  },
  challengeBox: {
    ...GlobalStyles.shadowSmall,
    ...GlobalStyles.informationBox,
    backgroundColor: Theme.secondaryMedium,
    borderColor: Theme.primaryMedium,
    width: '100%',
    minHeight: '50vh',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: Theme.spaceBig,
    paddingRight: Theme.spaceBig,
    paddingTop: Theme.spaceBig,
    paddingBottom: Theme.spaceBig,
  },
  challenge: {
    fontSize: Theme.fontSizeH4
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "column"
  },
  right: {
    display: "flex",
    justifyContent: "flex-end"
  },
  center: {
    display: "flex",
    justifyContent: "center"
  }

};