import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {Player} from "../../State/GameState/Types";
import {selectPlayers} from "../../State/GameState/Selectors";
import {Routes} from "../../Navigation/Routes";
import {TabNavigator} from "../../Components/Navigation/TabNavigator/TabNavigator";
import {styles} from "./LeaderboardStyles";
import {PlayerCard} from "../../Components/Custom/PlayerCard/PlayerCard";
import {Col, Container, Row} from "react-bootstrap";
import {PHONE_WIDTH} from "../../Config/Constants";
import {useWindowWidth} from "@react-hook/window-size/dist/es";

const Leaderboard: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const width = useWindowWidth();

  const players: Player[] = selectPlayers();

  useEffect(() => {
    if (players.length === 0)
      history.push(Routes.AddPlayers);
  }, []);

  return (
      <Container style={width <= PHONE_WIDTH ? styles.containerPhone : styles.container}>
        <Row noGutters style={styles.mainRow}>
          {players.map((player: Player, pos) => (
              <Col key={pos} md={4} lg={3} xs={6}>
                <PlayerCard player={player}/>
              </Col>
          ))}
        </Row>

        <TabNavigator/>
      </Container>
  )
};

export default Leaderboard;