import React, {FC} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomeScreen from "../Screens/HomeScreen/HomeScreen";
import AddPlayers from "../Screens/AddPlayersScreen/AddPlayers";
import TutorialScreen from "../Screens/TutorialScreen/TutorialScreen";
import Characters from "../Screens/CharactersScreen/Characters";
import Leaderboard from "../Screens/LeaderboardScreen/Leaderboard";
import PlayerPowers from "../Screens/PlayerPowersScreen/PlayerPowers";
import EtilicBattle from "../Screens/EtilicBattleScreen/EtilicBattle";
import Sitemap from "../Screens/SitemapScreen/Sitemap";
import AdminPanel from "../Admin/Screens/AdminPanelScreen/AdminPanel";
import About from "../Screens/AboutScreen/About";
import NotFound from "../Screens/NotFoundScreen/NotFound";
import {Routes} from "./Routes";

type Props = {};

export const Navigator: FC<Props> = () => {
  return (
      <Router>
        <div style={{height: '100%'}}>
          <Switch>
            <Route exact path={Routes.Home} component={HomeScreen}/>
            <Route path={Routes.AddPlayers} component={AddPlayers}/>
            <Route path={Routes.Tutorial} component={TutorialScreen}/>
            <Route path={Routes.OnlyTutorial}><TutorialScreen onlyTutorial/></Route>
            <Route path={Routes.Characters} component={Characters}/>
            <Route path={Routes.Leaderboard} component={Leaderboard}/>
            <Route path={Routes.PlayerCard} component={PlayerPowers}/>
            <Route path={Routes.EtilicBattle} component={EtilicBattle}/>
            <Route path={Routes.Sitemap} component={Sitemap}/>
            <Route path={Routes.AdminPanel} component={AdminPanel}/>
            <Route path={Routes.About} component={About}/>
            <Route component={NotFound}/>
          </Switch>
        </div>
      </Router>
  )
}