import * as firebase from "firebase";
import {BattleMode, Challenge} from "../State/GameState/Types";
import {currentTimeHuman} from "../Utils/GeneralUtils";
import {COVID} from "../Config/Constants";
import {Base64} from "js-base64";

const getPath = (dev) => dev ? 'dev-analytics' : 'analytics';
type EventType = { type: 'new-challenge' | 'new-power' | 'change-mode' | 'read-tutorial', date: string, payload: string }

const logNewEvent = async (gameID: string, development: boolean, event: EventType) => {
  // Update finish time
  firebase.database().ref(`/${getPath(development)}/games/${gameID}/finish_time`)
      .set(currentTimeHuman())
      .catch(e => console.error('Error on changing finish time - ', e));

  // Log game event
  firebase.database().ref(`/${getPath(development)}/games/${gameID}/log/${event.date} - ${event.type}`)
      .set(event)
      .catch(e => console.error('Error on pushing event - ', e));
}

export const Statistics = {
  logNewChallenge: async (challenge: Challenge, time: number, development: boolean, gameID: string) => {
     const event: EventType = {
      date: currentTimeHuman(),
      type: "new-challenge",
      payload: challenge.challenge
    };

    logNewEvent(gameID, development, event);

    const challengePath = challenge.challenge.replace(/\.|\#|\$|\[|\]|\/|\n/g, '').toLowerCase();
    firebase.database().ref(`/${getPath(development)}/challenges/${challengePath}/times`)
        .push(time)
        .catch(e => console.error('Error on pushing time for challenge - ', e));
  },

  logNewGame: async (playersNumbers: number, language: string, development: boolean, gameID: string, playerNames: string[]) => {
    const information = {
      number_players: playersNumbers,
      language: language,
      covid: COVID,
      players: Base64.encode(playerNames),
      start_time: currentTimeHuman(),
      finish_time: currentTimeHuman(),
      log: []
    };

    firebase.database().ref(`/${getPath(development)}/games/${gameID}`)
        .set(information)
        .catch(e => console.error('Error on pushing new game - ', e));
  },

  logNewPower: async (playerName: string, powerNumber: number, time: number, development: boolean, gameID: string) => {
    const event: EventType = {
      date: currentTimeHuman(),
      type: "new-power",
      payload: `${playerName} UNLOCKS power #${powerNumber}`
    };

    logNewEvent(gameID, development, event);

    const playerPath = playerName.replace(/\.|\#|\$|\[|\]|\/|\n/g, '').toLowerCase();
    firebase.database().ref(`${getPath(development)}/powers/${playerPath}/${powerNumber+1}/times`)
        .push(time)
        .catch(e => console.error('Error on pushing power - ', e));
  },

  logChangeMode: async (mode: string, time: number, development: boolean, gameID: string) => {
    const event: EventType = {
      type: "change-mode",
      date: currentTimeHuman(),
      payload: `Changed GAME MODE to ${mode === BattleMode.Hot ? 'NORMAL' : 'HOT'}`
    };

    logNewEvent(gameID, development, event);

    firebase.database().ref(`${getPath(development)}/game-mode/${mode}/times`)
        .push(time)
        .catch(e => console.error('Error on pushing time for game mode - ', e));
  },

  logReadTutorial: async (time: number, development: boolean, gameID: string) => {
    const event: EventType = {
      type: "read-tutorial",
      date: currentTimeHuman(),
      payload: `Read TUTORIAL for ${time} seconds`
    };

    logNewEvent(gameID, development, event);

    firebase.database().ref(`${getPath(development)}/tutorial/times`)
        .push(time)
        .catch(e => console.error('Error on pushing time for tutorial - ', e));
  },
};