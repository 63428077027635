import {GlobalStyles, StyleSheet} from "../../Theme/GlobalStyles";
import {Theme} from "../../Theme/Theme";

export const styles: StyleSheet = {
  box: {
    ...GlobalStyles.shadowSmall,
    ...GlobalStyles.informationBox,
  },
  boxCovid: {
    ...GlobalStyles.shadowSmall,
    ...GlobalStyles.informationBox,
    backgroundColor: Theme.danger,
    width: '100%'
  },
  image: {
    width: '36vmin',
  },
  footer: {
    bottom: Theme.spaceSmall
  },
  back: {
    backgroundColor: Theme.primaryMedium,
    height: '100%',
    overflowY: "auto"
  },
  button: {
    lineHeight: 1
  }
};