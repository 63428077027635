import React, {FC, useEffect, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import AddChallenge from "../../Components/AddChallengeComponent/AddChallenge";
import EditChallenges from "../../Components/EditChallengesComponent/EditChallenges";
import * as firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

const AdminPanel: FC = (props: any) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  let firebaseConfig = {
    apiKey: "AIzaSyDT03WJGiaNeZn3NJvVVkAc1nYpJ4ykol0",
    authDomain: "hello-world-a6bb0.firebaseapp.com",
    databaseURL: "https://hello-world-a6bb0.firebaseio.com",
    projectId: "hello-world-a6bb0",
    storageBucket: "hello-world-a6bb0.appspot.com",
    messagingSenderId: "682416459862",
    appId: "1:682416459862:web:039cf69eb3fe5d0e",
    measurementId: "G-BL0GG7E2ET"
  };

  if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

  const uiConfig = {
    signInFlow: 'popup',
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        console.log('Login successful');
        return false
      }
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(
        (user) => {
          if (user.email === "matias.szbar@gmail.com" ||
              user.email === "joak.crow@gmail.com" ||
              user.email === "caterina.cognini8@gmail.com")
            setIsSignedIn(!!user)
        }
    )
  }, []);

  if (isSignedIn) {
    return (
        <div className='mx-2'>

          <Typography align='center' variant='h5' className='mt-5 mb-2 whiteColor'>
            Panel de control
          </Typography>

          <Card className='p-4 w-100'>
            <Tabs>
              <TabList>
                <Tab>Añadir retos</Tab>
                <Tab>Editar retos</Tab>
              </TabList>
              <TabPanel>
                <AddChallenge/>
              </TabPanel>
              <TabPanel>
                <EditChallenges/>
              </TabPanel>
            </Tabs>
          </Card>
        </div>
    )
  } else return (
      <div>
        <Typography align='center' variant='h5' className='mt-5 mb-2 whiteColor'>
          Panel de control
        </Typography>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </div>
  )
};

export default AdminPanel;