import {createMuiTheme} from "@material-ui/core";

export const MaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#383838',
      light: '#616161',
      dark: '#121212',
      contrastText: '#ffffff',
      // textPrimary: '#ffffff'
    },
    secondary: {
      main: '#d9761a',
      light: '#ffb65c',
      dark: '#a15000',
      contrastText: '#000000',
      // textPrimary: '#000000'
    },
    background: {
      paper: '#ffffff',
      default: '#4f5b62'
    },
  },
  overrides: {
    MuiTypography: {
      // colorInherit: "#ffffff",
      // colorTextSecondary: "#ffffff",
      // colorSecondary: "#ffffff"
    }
  },
  typography: {
    useNextVariants: true,
    h5: {
      fontFamily: "'Bungee', cursive"
    },
    h6: {
      fontFamily: "'Bungee', cursive"
    }
  }
});
