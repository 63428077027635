import React, {FC} from "react";
import {Player} from "../../../State/GameState/Types";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import {updateCurrentOpenPlayerAction} from "../../../State/GameState/Actions";
import {Routes} from "../../../Navigation/Routes";
import {useHistory} from "react-router-dom";
import {styles} from "./PlayerCardStyles"
import {Text} from "../../Text/TextComponent";

type Props = {
  player: Player,
  single?: boolean
};

export const PlayerCard: FC<Props> = ({player, single}) => {
  const history = useHistory();

  const selectPlayerAndOpenCard = (player: Player) => {
    updateCurrentOpenPlayerAction(player);
    history.push(Routes.PlayerCard);
  };

  return (
      <div style={single ? styles.singleCard : styles.card} onClick={() => selectPlayerAndOpenCard(player)}>
        <Text text={player.name} h3 style={styles.name}/>
        <Badge color='primary' badgeContent={player.character.drunk}>
          <Avatar alt='' src={player.character.image} className='avatar' style={styles.avatar}/>
        </Badge>
        <Text text={player.character.display.toUpperCase()} bold/>
      </div>
  )
};