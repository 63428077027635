import {PHONE_WIDTH} from "../Config/Constants";

// @ts-ignore
const isPhone = window.innerWidth <= PHONE_WIDTH;

export const Theme = {
  // Colors
  primaryLighter: '#FFB65C',
  primaryLight: '#EB9539',
  primaryMedium: '#D97619',
  primaryDark: '#A15000',
  secondaryLighter: '#ADADAD',
  secondaryLight: '#616161',
  secondaryMedium: '#383838',
  secondaryDark: '#121212',
  accent: '#30E843',
  white: '#FFFFFF',
  black: '#000000',
  danger: '#D73232',
  background: '#4E5B63',
  transparent: 'rgb(0, 0, 0, 0)',

  // Font
  fontSizeH1: '8vmin',
  fontSizeH2: '3em',
  fontSizeH3: '2.5em',
  fontSizeH4: '2em',
  fontSizeH5: '1.5em',
  fontSizeMedium: '1em',
  fontFamilyTitle: 'Bungee',
  fontFamilyNormal: 'Montserrat',

  // Spacing
  spaceBigXL: 32,
  spaceBig: 16,
  spaceMedium: 8,
  spaceSmall: 4,
  paddingBigXL: isPhone ? 64 : '32vmin',
  paddingBig: isPhone ? 32 : '16vmin',
  paddingMedium: isPhone ? 24 : '8vmin',
  paddingSmall: isPhone ? 8 : '2vmin',

  // Buttons
  buttonRadius: 7,
  buttonBorder: 2,

  // Navbar
  barHeight: 64,
  navigationBar: '120px'
}