import React, {FC, useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Lock from '@material-ui/icons/Lock';
import Close from '@material-ui/icons/Close';
import Grade from '@material-ui/icons/Grade';
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';
import {Power} from "../../State/GameState/Types";
import {useWindowWidth} from "@react-hook/window-size/throttled";
import {selectCurrentOpenPlayer, selectLastPower, selectLastPowerOwner} from "../../State/GameState/Selectors";
import {Routes} from "../../Navigation/Routes";
import {unlockLastPowerOfCurrentAction, unlockPowerOfOpenAction} from "../../State/GameState/Actions";
import {PlayerCard} from "../../Components/Custom/PlayerCard/PlayerCard";
import {Col, Container, Row} from "react-bootstrap";
import {styles} from "./PlayerPowersStyles";
import {GlobalStyles} from "../../Theme/GlobalStyles";
import {PHONE_WIDTH} from "../../Config/Constants";
import {PlayerObligations} from "../../Components/Custom/PlayerObligations/PlayerObligations";
import {Theme} from "../../Theme/Theme";
import {Text} from "../../Components/Text/TextComponent";


const PlayerPowers: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const width = useWindowWidth();

  const currentPlayer = selectCurrentOpenPlayer();
  const lastPowerOwner = selectLastPowerOwner();
  const lastPower = selectLastPower();

  const [expanded, setExpanded] = useState([false, false, false, false, false, false, false, false, false]);
  const [powerDialog, setPowerDialog] = useState(false);
  const [lastPos, setLastPos] = useState(null);
  const [powerNumUnlocking, setPowerNumUnlocking] = useState<number>();

  useEffect(() => {
    if (!currentPlayer)
      history.push(Routes.Leaderboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPower = (power: Power, pos: number) => {
    if (currentPlayer.character.drunk >= pos) {
      if (power.unlocked) {
        let exp = [...expanded];
        exp[pos] = !exp[pos];
        setExpanded(exp);
      } else {
        setPowerNumUnlocking(pos)
        setPowerDialog(true);
        setLastPos(pos)
      }
    }
  };

  const onConfirmPower = () => {
    unlockPowerOfOpenAction(powerNumUnlocking)
    let exp = [...expanded];
    exp[lastPos] = !exp[lastPos];
    setExpanded(exp);
    setPowerDialog(false);
  };

  const onUnlockLastPower = () => {
    if (currentPlayer.character.drunk === 8) {
      unlockLastPowerOfCurrentAction()
      let exp = [...expanded];
      exp[8] = !exp[8];
      setExpanded(exp);
    }
    if (currentPlayer.character.drunk === 9) {
      let exp = [...expanded];
      exp[8] = !exp[8];
      setExpanded(exp);
    }
  };

  const getLastPowerTitle = () => {
    if (lastPowerOwner === undefined || lastPowerOwner.character.name === currentPlayer.character.name)
      return t('Player.superpower');
    else
      return t('Player.superpowerTaken', {owner: lastPowerOwner.name});
  };

  const panelColor = (i: number) => {
    return ;
  }

  if (currentPlayer) {
    return (
        <Container>
          <Row>
            <Col xs={12} className="sticky-top">
              <IconButton className='float-right' onClick={history.goBack}>
                <Close color={"secondary"}/>
              </IconButton>
            </Col>

            <Col xs={12} md={4} style={GlobalStyles.centeredContainer}>
              <PlayerCard player={currentPlayer} single/>
              {width > PHONE_WIDTH && <PlayerObligations currentPlayer={currentPlayer}/> }
            </Col>

            <Col xs={12} md={7}>
              {currentPlayer.character.powers.map((power: Power, i: number) => (
                  <ExpansionPanel
                      expanded={expanded[i]} key={i}
                      style={{...styles.panel, backgroundColor: `rgb(${255 - i * 13}, ${182 - i * 13}, ${92 - i * 13})`}}
                      onClick={() => openPower(power, i)}
                  >
                    <ExpansionPanelSummary expandIcon={power.unlocked ? <ExpandMoreIcon/> : <Lock/>}>
                      <Text text={power.unlocked ? power.title : `${t('Player.power')} ${i + 1}`} bold/>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Text text={power.description} center={false}/>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
              ))}

              <ExpansionPanel expanded={expanded[8]} style={{...styles.panel, ...styles.superpower}} onClick={onUnlockLastPower}>
                <ExpansionPanelSummary expandIcon={currentPlayer.character.lastPower ? <ExpandMoreIcon/> : <Grade style={{color: 'white'}}/>}>
                  <Text text={getLastPowerTitle()} bold white/>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Text text={lastPower} center={false} white/>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Col>

            {width <= PHONE_WIDTH &&
            <Col xs={12} style={styles.bottomCard}>
              <PlayerObligations currentPlayer={currentPlayer}/>
            </Col>
            }
          </Row>

          <Dialog open={powerDialog} onClose={() => setPowerDialog(false)}>

            <Text text={t('Player.dialog.title')} h5 titleFont marginMedium/>
            <DialogContent>
              <DialogContentText style={GlobalStyles.centeredContainer}>
                <Text text={t('Player.dialog.content')} marginMedium bold/>
                <Button onClick={onConfirmPower} variant='outlined' color='secondary' fullWidth>
                  {t('Player.dialog.ok')}
                </Button>
                <Button onClick={() => setPowerDialog(false)} variant='outlined' style={styles.noButton} fullWidth>
                  {t('Player.dialog.no')}
                </Button>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Container>
    )
  } else
    return <></>
};

export default PlayerPowers;