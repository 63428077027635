import {GlobalStyles, StyleSheet} from "../../Theme/GlobalStyles";
import {Theme} from "../../Theme/Theme";
import {PHONE_WIDTH} from "../../Config/Constants";

// @ts-ignore
const isPhone = window.innerWidth <= PHONE_WIDTH;

export const styles: StyleSheet = {
  container: {
    ...GlobalStyles.containerDark,
  },
  bottomCard: {
    marginBottom: Theme.spaceBigXL
  },
  panel: {
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: Theme.buttonRadius,
    textAlign: "center",
    marginTop: isPhone ? Theme.spaceMedium : Theme.spaceBigXL,
    resize: "both",
  },
  superpower: {
    backgroundColor: Theme.secondaryMedium,
    borderColor: "#808080",
    borderWidth: 5,
    boxSizing: "border-box",
    marginBottom: isPhone ? 0 : Theme.spaceBigXL,
  },
  noButton: {
    marginTop: Theme.spaceMedium,
  }
};