import React, {FC} from "react";
import {styles} from "./PlayerObligationsStyles";
import {Text} from "../../Text/TextComponent";
import {Player} from "../../../State/GameState/Types";
import {useTranslation} from "react-i18next";

type Props = {
  currentPlayer: Player,
};

export const PlayerObligations: FC<Props> = ({currentPlayer}) => {
  const {t} = useTranslation();

  return (
      <div style={styles.card}>
        <Text text={t('Player.obligations')} h1 small/>
        {currentPlayer.character.obligations.map((obl: string, i) => (
            <>
              <Text text={obl} key={i}/>
              {i !== currentPlayer.character.obligations.length - 1 && <hr/>}
            </>
        ))}
      </div>
  )
};