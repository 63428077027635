import React, {FC, useEffect, useState} from 'react';
import './Characters.css';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Scrollable from 'hide-scrollbar-react';
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';
import {useWindowWidth} from "@react-hook/window-size/throttled";
import {selectPlayers} from "../../State/GameState/Selectors";
import {Player} from "../../State/GameState/Types";
import {Routes} from "../../Navigation/Routes";

const CharDescription: FC = (props: any) => {
  const {t} = useTranslation();

  return (
      <div>
        <Paper square className='charHeader d-flex align-items-center flex-column secondaryLightBack'>
          <Typography variant='h5' className='mt-2'><b>{props.player}</b></Typography>
          <Typography variant='h6' className='mt-2'>{props.name}</Typography>
        </Paper>

        <Scrollable className=''>

          <div className='charStep'>
            <div className="col">
              {/* Image */}
              <div className="row mb-3">
                <img src={props.img} className='img-fluid illustrateImage mx-auto' alt=''/>
              </div>

              {/* Description */}
              <div className="row mb-2">
                <Card className='fullWidth'>
                  <CardContent>
                    <Typography variant='h5'
                                component='h2'>{t('Characters.description')}</Typography>
                    <Typography variant='body2'>{props.description}</Typography>
                  </CardContent>
                </Card>
              </div>

              {/* Obligations */}
              <div className="row mb-3">
                <Card className='fullWidth'>
                  <CardContent>
                    <Typography variant='h5'
                                component='h2'>{t('Characters.obligations')}</Typography>
                    <ul>
                      {props.obligations.map((ob: string, pos: number) => (
                          <li key={pos}><Typography variant='body2'>{ob}</Typography></li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </Scrollable>

      </div>
  )
};

const Swiper: FC = (props: any) => {
  const {t} = useTranslation();
  const history = useHistory();

  let players: Player[] = [];
  players = selectPlayers();

  useEffect(() => {
    if (!players) history.push(Routes.AddPlayers);
  }, [])

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const navToLeaderboard = () => {
    console.log('Navigating to leader');
    history.push(Routes.Leaderboard)
  };

  return (
      <div>
        {/*Slides*/}
        <SwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
          {players.map((player: Player, pos) => (
              <CharDescription
                  key={pos}
                  img={player.character.image}
                  name={player.character.name}
                  player={player.name}
                  description={player.character.description}
                  obligations={player.character.obligations}
              />
          ))}

        </SwipeableViews>

        {/*Bottom Stepper*/}
        <MobileStepper
            position='static'
            className='secondaryLightBack'
            variant='text'
            steps={players.length}
            activeStep={activeStep}
            nextButton={
              activeStep !== players.length - 1 ?
                  <Button size="small" onClick={handleNext}>{t('Characters.buttons.next')}
                    <KeyboardArrowRight/></Button>
                  :
                  <Button size='small' onClick={navToLeaderboard}
                          color={"secondary"}>{t('Characters.buttons.start')}<KeyboardArrowRight/></Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft/> {t('Characters.buttons.previous')}
              </Button>
            }
        />
      </div>
  );
};

const Characters: FC = () => {
  const width = useWindowWidth();

  return (
      <div>
        {width < 576 ?
            <Swiper/>
            :
            <Paper className='desktopModal'>
              <Swiper/>
            </Paper>
        }
      </div>
  )
};

export default Characters;