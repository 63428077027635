import {StyleSheet} from "../../../Theme/GlobalStyles";
import {Theme} from "../../../Theme/Theme";
import {PHONE_WIDTH} from "../../../Config/Constants";

// @ts-ignore
const isPhone = window.innerWidth <= PHONE_WIDTH;

export const styles: StyleSheet = {
  card: {
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.25)",
    backgroundColor: Theme.primaryLighter,
    borderRadius: Theme.buttonRadius,
    padding: isPhone ? Theme.spaceSmall : Theme.spaceBig,
    textAlign: "center",
    marginTop: Theme.spaceBigXL,
    marginLeft: Theme.spaceBig,
    marginRight: Theme.spaceBig,
    resize: "both",
  },
};