import {Challenge, CharacterData, Player} from "../State/GameState/Types";
import {COVID, NAME_KEYWORD} from "../Config/Constants";
import {createRandomNumberArray, shuffleArray} from "./GeneralUtils";
import {getCharacters, getStartChallenges} from "../API/Storage";
import i18n from "i18next";

export const removeNullChallenges = (challenges: any): any => {
  if (COVID) return {
    en: removeNullElements(challenges['en']),
    es: removeNullElements(challenges['es']),
  }
  return {
    en: removeNullElements(challenges['en']),
    es: removeNullElements(challenges['es']),
    it: removeNullElements(challenges['it']),
    fr: removeNullElements(challenges['fr']),
  }
};

export const removeNullElements = (array: any[]): any[] => {
  return array.filter(item => item !== null)
};

export const getLabelFromChallenge = (challenge: Challenge, players: Player[]): { label: string, updatedPlayers: Player[] } => {
  let challengeLabel = challenge.challenge;
  let newPlayerObject = [...players];

  const numParticipants = (challengeLabel.match(new RegExp(NAME_KEYWORD, "g")) || []).length;
  const res = getLowestInteractionPlayers(numParticipants, newPlayerObject);

  const challengePlayersParticipants = res.challengePlayers;
  const updatedPlayers = res.updatedPlayers

  for (let i = 0; i < numParticipants; i++) {
    const replaceString = `<span style='color: #FFB65C'>${challengePlayersParticipants[i].name}</span>`;
    challengeLabel = challengeLabel.replace(`${NAME_KEYWORD}${i === 0 ? '' : i}`, replaceString)
  }

  return {label: challengeLabel, updatedPlayers: updatedPlayers};
};

const getLowestInteractionPlayers = (numPlayers: number, players: Player[]): { challengePlayers: Player[], updatedPlayers: Player[] } => {
  let newPlayersObject = [...players];

  const sortedPlayers = newPlayersObject.sort((a, b) =>
      a.interactions - b.interactions === 0 ? Math.random() - 0.5 : a.interactions - b.interactions
  );

  const participants = sortedPlayers.slice(0, numPlayers);

  const updated = newPlayersObject.map(player => {
    if (participants.includes(player)) {
      let updatedPlayer = Object.assign({}, player);
      updatedPlayer.interactions++;
      return updatedPlayer;
    } else {
      return player;
    }
  });

  return {challengePlayers: participants, updatedPlayers: updated};
}

export const loadEtilicBattle = (): Challenge[] => {
  const challenges: Challenge[] = getStartChallenges(i18n.language);
  return shuffleArray(challenges);
};

export const assignCharactersToPlayers = (playerNames: string[]): Player[] => {
  const characters: CharacterData[] = getCharacters(i18n.language);
  const randomArray = createRandomNumberArray(playerNames.length, characters.length);
  let players: Player[] = [];

  playerNames.forEach((name, i) => {
    let charData: CharacterData = characters[randomArray[i]];
    players.push({
      name: name,
      interactions: 0,
      character: {
        name: charData.name,
        display: charData.display,
        description: charData.description,
        obligations: charData.obligations,
        powers: charData.powers,
        image: require(`../Images/characters/${charData.imageName}`),
        drunk: 0,
        lastPower: false,
        lastPowerDate: new Date()
      }
    });
  });

  return players
};

export const getNextNonHotChallenge = (challenges: Challenge[], currentIndex: number): { updatedIndex: number, challenge: Challenge } => {
  let selectedChallenge: Challenge = challenges[currentIndex];
  let index = currentIndex;

  while (selectedChallenge.hot === 'y') {
    index++;
    selectedChallenge = challenges[index];
  }

  return {updatedIndex: index, challenge: selectedChallenge}
}