import moment from "moment";

export const getDaysFromDateToNow = (date: Date) => {
  const today = new Date().getTime();
  return Math.round((today - date.getTime()) / (1000 * 60 * 60 * 24));
};

export const shuffleArray = (a): any[] => {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

export const createRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * max) + min
};

export const createRandomNumberArray = (length: number, max: number): number[] => {
  let array = [];

  for (let i = 0; i < length; ++i) {
    let rand = Math.floor(Math.random() * max);
    while (array.includes(rand)) {
      if (rand < max - 1) rand++;
      else rand = Math.round(Math.random() * max);
    }
    array.push(rand);
  }
  return array;
};

export const secondsFromDate = (oldDate: Date, now: Date) => {
  return Math.floor((now.getTime() - oldDate.getTime()) / 1000);
}

export const createUUID = () => {
  const length = 20;
  const now = new Date();
  return `${now.getTime()}${Array(length).fill('').map((v) => Math.random().toString(36).charAt(2)).join('')}`;
}

export const currentTimeHuman = () => {
  return moment().format('DD MMMM YYYY, HH:mm:ss')
}