import {BattleMode, GameState, Player} from "./Types";
import {GameStore} from "./Store";
import {assignCharactersToPlayers, loadEtilicBattle} from "../../Utils/ChallengesUtils";
import {getLastPowers} from "../../API/Storage";
import i18n from "i18next";
import {createRandomNumber, secondsFromDate} from "../../Utils/GeneralUtils";
import {Statistics} from "../../API/Statistics";

export const createNewGameAction = (players: string[]) => {
  const newChallenges = loadEtilicBattle()

  const lastPowers: string[] = getLastPowers(i18n.language);
  const randomIndex = createRandomNumber(1, lastPowers.length);
  const newLastPower = lastPowers[randomIndex];

  const newPlayers = assignCharactersToPlayers(players);

  return GameStore.update(s => {
    Statistics.logNewGame(players.length, i18n.language, s.development, s.gameID, players);
    s.etilicBattle = newChallenges;
    s.lastPower = newLastPower;
    s.players = newPlayers;
  });
};

export const updateCurrentOpenPlayerAction = (player: Player) => {
  return GameStore.update(s => {
    s.openPlayer = player
  });
}

export const unlockPowerOfOpenAction = (powerNumber: number) => {
  return GameStore.update(s => {
    const now = new Date()
    const powerTime = secondsFromDate(new Date(s.openPlayer.character.lastPowerDate), now);
    Statistics.logNewPower(s.openPlayer.character.name, s.openPlayer.character.drunk, powerTime, s.development, s.gameID);

    s.openPlayer.character.powers[powerNumber].unlocked = true;
    s.players.find(p => p.character.name === s.openPlayer.character.name).character.powers[powerNumber].unlocked = true;
    s.openPlayer.character.drunk++
    s.players.find(p => p.character.name === s.openPlayer.character.name).character.drunk++;
    s.openPlayer.character.lastPowerDate = new Date();
  });
}

export const unlockLastPowerOfCurrentAction = () => {
  return GameStore.update(s => {
    s.players.find(p => p.character.name === s.openPlayer.character.name).character.lastPower = true;
    s.players.find(p => p.character.name === s.openPlayer.character.name).character.drunk = 9;
    s.lastPowerOwner = s.openPlayer;
  });
}

export const updateLastInteractionAction = (player: Player) => {
  return GameStore.update(s => {
    s.lastInteractedPlayer = player
  });
};

export const incrementPlayerInteractionsAction = (playerIndex: number) => {
  return GameStore.update(s => {
    const foundPlayer: Player = {...s.players[playerIndex]};
    foundPlayer.interactions++;
    s.players[playerIndex] = foundPlayer;
  });
};

export const updateWholeGameAction = (gameState: GameState) => {
  return GameStore.update(s => {
    const keys = Object.keys(gameState);
    for (const key in keys)
      if (gameState[key]) s[key] = gameState[key]
  });
};

export const toggleBattleModeAction = () => {
  return GameStore.update(s => {
    const now = new Date()
    const modeTime = secondsFromDate(s.lastModeChangeDate, now);
    Statistics.logChangeMode(s.gameMode, modeTime, s.development, s.gameID);

    if (s.gameMode === BattleMode.Normal)
      s.gameMode = BattleMode.Hot;
    else
      s.gameMode = BattleMode.Normal;

    s.lastModeChangeDate = new Date();
  });
};

export const updateCurrentChallenge = () => {
  return GameStore.update(s => {
    s.nextBattleChallengeNumber++;
  })
}

export const updateDevelopmentMode = (state: boolean) => {
  if (state) console.info('[DEV] Development options are', state)
  return GameStore.update(s => {
    s.development = state;
  })
}