import {BattleMode, Challenge, GameState} from "./Types";
import {getLabelFromChallenge, getNextNonHotChallenge} from "../../Utils/ChallengesUtils";
import {Statistics} from "../../API/Statistics";
import {secondsFromDate} from "../../Utils/GeneralUtils";
import {saveWholeState} from "../../API/Storage";

export const updateChallengeSubscription = (updatedNumber: any, draft: GameState, original: GameState) => {
  const newIndex = draft.nextBattleChallengeNumber

  // Log to analytics
  if (original.lastChallengeDate) {
    const now = new Date()
    const challengeTime = secondsFromDate(original.lastChallengeDate, now);
    Statistics.logNewChallenge(original.etilicBattle[original.nextBattleChallengeNumber - 1], challengeTime, draft.development, draft.gameID);
  }

  let selectedChallenge: Challenge = original.etilicBattle[newIndex];

  if (original.gameMode === BattleMode.Normal) {
    const {updatedIndex, challenge} = getNextNonHotChallenge(original.etilicBattle, newIndex);
    draft.nextBattleChallengeNumber = updatedIndex;
    selectedChallenge = challenge;
  }

  const res = getLabelFromChallenge(selectedChallenge, original.players);
  draft.currentChallenge = res.label;
  draft.players = res.updatedPlayers;
  draft.lastChallengeDate = new Date();
}

export const printStateSubscription = (watching: GameState, draft: GameState, original: GameState) => {
  if (original.development) console.log('[DEV] Current State:', original);
}

export const saveUpdatedState = (watching, draft, original: GameState) => {
  saveWholeState(original);
};