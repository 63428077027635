import * as firebase from 'firebase/app';
import 'firebase/database';
import {
  getLastSyncDate,
  saveCharacters,
  saveLastPowers,
  saveLastSync,
  saveStartChallenges
} from "./Storage";
import {getDaysFromDateToNow} from "../Utils/GeneralUtils";
import {Challenge} from "../State/GameState/Types";
import {Languages} from "./Types";
import {COVID, VALID_CACHED_DAYS} from "../Config/Constants";

export const syncData = () => {
  const lastSynchronizedDate = getLastSyncDate();

  if (!lastSynchronizedDate || getDaysFromDateToNow(lastSynchronizedDate) > VALID_CACHED_DAYS) {
    console.info('Need to sync');

    firebase.database().ref('/challenges').once('value').then(snapshot => {
      saveStartChallenges(COVID ? snapshot.val().covid : snapshot.val().start);
      saveLastSync();
    });
    firebase.database().ref('/characters').once('value').then(snapshot => {
      saveCharacters(COVID ? snapshot.val().covid : snapshot.val());
      saveLastSync();
    });
    firebase.database().ref('/lastPower').once('value').then( snapshot => {
      saveLastPowers(snapshot.val());
      saveLastSync();
    });
  } else console.log('No need to sync')
};

export const createNewBattleChallenge = async (challenge: Challenge, language: Languages): Promise<any> => {
  const hotMode = challenge.hot ? 'y' : 'n';
  const payload = {
    'challenge': challenge.challenge,
    'hot': hotMode
  };

  const snapshot = await firebase.database().ref(`/lastIndex/start/${language}`).once('value');
  let index = parseInt(snapshot.val()) + 1;

  const res = await firebase.database().ref(`/lastIndex/start/${language}`).set(index);
  if (res.error)
    console.error('[Cloud] Error updating last index.', res.error);

  if (!res.error)
    return await firebase.database().ref(`/challenges/start/${language}/${index}`).set(payload);
};

export const getChallengesInLanguage = async (language: Languages, moment: string): Promise<Challenge[]> => {
  const res = await firebase.database().ref(`/challenges/${moment}/${language}`).once('value');
  return res.val();
};

export const saveChangeChallenge = async (challenge: Challenge, index: number, language: Languages, moment: string): Promise<any> => {
  if (moment === 'start' || moment === 'middle')
    return await firebase.database().ref(`/challenges/${moment}/${language}/${index}`).set(challenge);
};

export const deleteChallenge = async (index: number, lang: string, moment: string): Promise<any> => {
  if (moment === 'start' || moment === 'middle')
    return await firebase.database().ref(`/challenges/${moment}/${lang}/${index}`).remove();
};