import React, {FC, useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import './EditChallenges.css';
import TextField from "@material-ui/core/TextField";
import {deleteChallenge, getChallengesInLanguage, saveChangeChallenge} from "../../../API/Cloud";
import {Languages} from "../../../API/Types";

const Edit: FC = (props: any) => {

  const [challenge, setChallenge] = useState(props.challenge.challenge);
  const [hot, setHot] = useState(props.challenge.hot);

  return (
      <div>
        <TextField className='w-100' multiline rows='2' variant='outlined'
                   value={challenge}
                   onChange={ev => setChallenge(ev.target["value"])}
        />
        {/* Botones */}
        <div className="row">
          {/* Guardar */}
          <div className="col"><Button
              onClick={() => props.onSave({
                challenge: challenge,
                hot: hot
              })}>Guardar</Button></div>
          {/* Delete */}
          <div className="col"><Button onClick={() => props.onDelete()}>Eliminar</Button></div>
          {/* Hot or not */}
          <div className="col">Hot <Switch checked={challenge.hot === 'y'}
                                           onChange={ev => setHot(ev.target.checked)}/>
          </div>
        </div>
      </div>
  )
};

const EditChallenges: FC = (props: any) => {
  const [selectedLang, setSelectedLang] = useState<Languages>(Languages.Spanish);
  const [selectedMoment, setSelectedMoment] = useState('start');
  const [challenges, setChallenges] = useState();
  const [index, setIndex] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [errMsg, setErrMsg] = useState();

  useEffect(() => {
    getChallenges(selectedLang, selectedMoment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Gets updated challenges
   *
   * @param lang Language
   * @param moment Moment
   * */
  const getChallenges = async (lang, moment) => {
    setChallenges(null);

    const challenges = await getChallengesInLanguage(lang, moment);
    setChallenges(challenges)
  };

  /**
   * Change language
   *
   * @param lang Lang
   */
  const changeLang = (lang) => {
    setSelectedLang(Languages[lang]);
    getChallenges(selectedLang, selectedMoment);
  };

  /**
   * Change moment
   *
   * @param moment Moment
   */
  const changeMoment = (moment) => {
    setSelectedMoment(moment);
    getChallenges(selectedLang, selectedMoment);
  };

  /**
   * Opens edit dialog
   *
   * @param: index Index
   * */
  const edit = (index) => {
    setIndex(index);
  };

  /**
   * Saves changes
   *
   * @param index Index
   * @param challenge Challenge
   * */
  const onSaveChanges = async (challenge, index) => {
    const res = await saveChangeChallenge(challenge, index, selectedLang, selectedMoment);
    if (res.error) {
      setError(index);
      setErrMsg(res.error);
    } else {
      let newChallenges = challenges;
      newChallenges[index] = challenge;
      setIndex(null);
      setSuccess(null);
      setChallenges(newChallenges);
    }
  };

  /**
   * Deletes a challenge
   *
   * @param index Index
   * */
  const onDeleteChallenge = async (index) => {
    const res = await deleteChallenge(index, selectedLang, selectedMoment);
    if (res.error) {
      setError(index);
      setErrMsg(res.error);
    } else {
      let newChallenges = challenges;
      newChallenges[index] = 'deleted';
      setIndex(null);
      setSuccess(null);
      setChallenges(newChallenges);
    }
  };

  return (
      <div>

        {/* Lang and moment selectors */}
        <div className="row">
          <div className="col"><Button onClick={() => changeLang('es')}><span role='img'
                                                                              aria-label='es'>🇪🇸</span></Button>
          </div>
          <div className="col"><Button onClick={() => changeLang('en')}><span role='img'
                                                                              aria-label='en'>🇬🇧</span></Button>
          </div>
          <div className="col"><Button onClick={() => changeLang('fr')}><span role='img'
                                                                              aria-label='fr'>🇫🇷</span></Button>
          </div>
          <div className="col"><Button onClick={() => changeLang('it')}><span role='img'
                                                                              aria-label='it'>🇮🇹</span></Button>
          </div>
          <div className="col"><Button onClick={() => changeMoment('start')}>Start</Button></div>
          <div className="col"><Button onClick={() => changeMoment('middle')}>Middle</Button></div>
        </div>

        {/* Instructions */}
        <Typography variant='caption'>En naranja son hot. Haz click para editar un reto.</Typography>

        {/* Loading */}
        {!challenges && <CircularProgress/>}

        {/* Challenges */}
        {challenges && challenges.map((ch, pos) => (
            <div key={pos}>
              {ch === 'deleted' &&
              <Typography style={{color: '#18B618'}}><b>Eliminado correctamente.</b></Typography>}
              {index === pos ?
                  <div key={pos}>
                    <Typography variant='h6' className='mt-2'>Reto número {pos}</Typography>
                    <Edit challenge={ch} onDelete={() => onDeleteChallenge(pos)}
                          onSave={(ch) => onSaveChanges(ch, pos)}/>
                    {error === pos &&
                    <Typography style={{color: '#B63418'}}><b>Ha ocurrido un error: </b>{errMsg}
                    </Typography>}
                  </div>
                  :
                  <div key={pos} onClick={() => edit(pos)} className='cursorOver'>
                    <Typography variant='h6' className='mt-2'>Reto número {pos}</Typography>
                    <Typography
                        style={{color: ch.hot === 'y' ? '#a15000' : '#000000'}}>{ch.challenge}</Typography>
                    {success === pos &&
                    <Typography style={{color: '#18B618'}}><b>Cambios guardados
                        correctamente</b></Typography>}
                  </div>
              }
            </div>
        ))}
      </div>
  )
};
export default EditChallenges;