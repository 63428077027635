import React, {FC, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';
import {useWindowWidth} from "@react-hook/window-size";
import {Routes} from "../../Navigation/Routes";
import {selectBattleMode, selectCurrentChallenge, selectPlayers} from "../../State/GameState/Selectors";
import {BattleMode} from "../../State/GameState/Types";
import {toggleBattleModeAction, updateCurrentChallenge} from "../../State/GameState/Actions";
import {Col, Container, Row} from "react-bootstrap";
import {styles} from "./EtilicBattleStyles";
import {Text} from "../../Components/Text/TextComponent";
import {TextButton} from "../../Components/Buttons/TextButton";
import {ModeButton} from "../../Components/Buttons/ModeButton";
import {PHONE_WIDTH} from "../../Config/Constants";
import {TabNavigator} from "../../Components/Navigation/TabNavigator/TabNavigator";

const EtilicBattle: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const width = useWindowWidth();
  const [prevChallenge, setPrevChallenge] = useState<string>();
  const [showPrevChallenge, setShowPrevChallenge] = useState<boolean>(false);

  const battleMode = selectBattleMode();
  const displayChallenge = selectCurrentChallenge()
  const players = selectPlayers();

  if (players.length === 0)
    history.push(Routes.AddPlayers);

  const changeMode = () => {
    setPrevChallenge(displayChallenge);
    toggleBattleModeAction();
    updateCurrentChallenge();
  };

  const onNext = () => {
    if (showPrevChallenge) {
      setShowPrevChallenge(false);
    } else {
      setPrevChallenge(displayChallenge);
      updateCurrentChallenge();
    }
  }

  const onPrev = () => {
    if (prevChallenge)
      setShowPrevChallenge(true);
  }

  return (
      <Container style={width <= PHONE_WIDTH ? styles.containerPhone : styles.container}>
        <div style={styles.challengeBox}>
          <Text
              text={ReactHtmlParser(showPrevChallenge ? prevChallenge.toUpperCase() : displayChallenge.toUpperCase())}
              white h4 bold style={{lineHeight: 1.2}}
          />
        </div>

        <Container>
          <Row>
            <Col sm={{span: 4, order: 1}} xs={{span: 6, order: 1}}>
              <TextButton
                  onPress={onPrev} text={t('EtilicBattle.previousSm')} full
                  disabled={showPrevChallenge && prevChallenge !== undefined}
              />
            </Col>
            <Col sm={{span: 4, order: 2}} xs={{span: 12, order: 3}} style={styles.center}>
              <ModeButton onToggle={changeMode} initialHot={battleMode === BattleMode.Hot}/>
            </Col>
            <Col sm={{span: 4, order: 3}} xs={{span: 6, order: 2}} style={styles.right}>
              <TextButton onPress={onNext} text={t('EtilicBattle.nextSm')} full/>
            </Col>
          </Row>
        </Container>

        <TabNavigator/>
      </Container>
  )
};

export default EtilicBattle;