export const Routes = {
  Home: "/",
  AddPlayers: "/players",
  Tutorial: "/tutorial",
  OnlyTutorial: "/view-tutorial",
  Characters: "/characters",
  Leaderboard: "/leaderboard",
  PlayerCard: "/player",
  EtilicBattle: "/start",
  Sitemap: "/sitemap",
  AdminPanel: "/adminpanel",
  About: "/about",
};