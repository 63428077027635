import {StyleSheet} from "../../Theme/GlobalStyles";
import {Theme} from "../../Theme/Theme";

export const styles: StyleSheet = {
  flame: {
    height: Theme.fontSizeH4,
    display: "inline",
    marginLeft: 8,
    marginRight: 8,
  },
  navContainer: {
    display: "flex",
    flexDirection: "column"
  },
  navIcon: {
    height: Theme.fontSizeH4,
    resize: "both",
    display: "inline",
    marginRight: 8,
  },
  navText: {
    display: "inline"
  },
  navButton: {
    padding: 0,
    alignContent: "center"
  }
};