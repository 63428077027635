import React, {FC} from "react";
import {styles} from "./TutorialStepStyles";
import {Text} from "../../Text/TextComponent";
import {useTranslation} from "react-i18next";

type Props = {
  phone?: boolean,
  number: number,
  title: string,
  description: string
};

export const TutorialStep: FC<Props> = ({number, title, description, phone}) => {
  const {t} = useTranslation();

  return (
      <div style={phone ? styles.phoneParent : styles.desktopParent}>
        {phone ?
            <div style={styles.phoneContainer}>
              <Text text={t('Tutorial.step', {number})} h3 white center={false} style={styles.phoneStep}/>
              <Text text={title} h5 white center={false} style={styles.phoneTitle}/>
            </div>
            :
            <div style={styles.desktopContainer}>
              <Text text={t('Tutorial.step', {number})} h3 white center={false} style={styles.desktopFontTitle}/>
              <Text text={title} h5 white center={false} style={styles.desktopFontSubtitle} primary/>
            </div>
        }
        <div style={phone ? styles.phoneBox : styles.desktopBox}>
          <Text text={description} style={styles.descriptionText} bold/>
        </div>
      </div>
  )
};