export type Obligation = string;

export type Power = {
  title: string
  description: string
  unlocked: boolean
};

export type Character = {
  name: string
  display: string
  description: string
  obligations: Obligation[]
  powers: Power[]
  image: any
  drunk: number
  lastPower: boolean,
  lastPowerDate: Date,
};

export type CharacterData = {
  name: string
  display: string
  description: string
  obligations: Obligation[]
  powers: Power[]
  imageName: string
};

export type Challenge = {
  challenge: string
  hot: string
};

export type Player = {
  name: string,
  interactions: number,
  character: Character
};

export enum BattleMode {
  Hot = 'HOT',
  Normal = 'NORMAL'
}

export interface GameState {
  etilicBattle: Challenge[];
  nextBattleChallengeNumber: number;
  lastInteractedPlayer: Player;
  players: Player[];
  lastPower: string;
  lastPowerOwner: Player;
  gameMode: BattleMode;
  currentChallenge: string;
  openPlayer: Player;
  lastChallengeDate: Date;
  development: boolean;
  gameID: string;
  lastModeChangeDate: Date;
}