import React, {FC} from 'react';
import './NotFound.css';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';

const NotFound: FC = () => {
  const {t} = useTranslation();

  return (
      <div className='container'>
        <Card className='mt-5'>
          <CardContent>
            {/*Title*/}
            <Typography variant='h5' className='text-center'>
              {t('NotFound.title')} <span role='img' aria-label='Not Found'>🤷</span>
            </Typography>

            {/*Text*/}
            <Typography variant='body2' className='text-center mt-3'>
              {t('NotFound.text.1')} <a href="/">{t('NotFound.text.2')}</a>.
            </Typography>
          </CardContent>
        </Card>
      </div>
  )
};

export default NotFound;