import React, {FC, useEffect, useState} from 'react';
import './AddPlayers.css';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from "@material-ui/core/Grow";
import Card from "@material-ui/core/Card";
import {Clear} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {useTranslation} from 'react-i18next';
import i18n from "i18next";
import {useHistory} from 'react-router-dom';
import {getOverAge, getWholeState, saveOverAge} from "../../API/Storage";
import {useWindowWidth} from "@react-hook/window-size/throttled";
import {MAX_PLAYERS} from "../../Config/Constants";
import {Routes} from "../../Navigation/Routes";
import {createNewGameAction, updateCurrentChallenge, updateWholeGameAction} from "../../State/GameState/Actions";
import {syncData} from "../../API/Cloud";
import {GameState} from "../../State/GameState/Types";

const engIcon = require('../../Images/flags/eng.png');
const espIcon = require('../../Images/flags/esp.png');

type Props = {}

const AddPlayers: FC<Props> = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const width = useWindowWidth();

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isAgeDialogOpen, setIsAgeDialogOpen] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [players, setPlayers] = useState(['', '', '', '']);

  useEffect(syncData, []);

  const handleWritePlayer = (e) => {
    let newPlayers = [...players];
    newPlayers[e.target.id] = e.target.value;
    setPlayers(newPlayers)
  };

  const addPlayerField = () => {
    let newPlayers = [...players];
    newPlayers.push('');
    setPlayers(newPlayers)
  };

  const deletePlayerField = (pos: number) => {
    let newPlayers = [...players];
    newPlayers.splice(pos, 1);
    setPlayers(newPlayers)
  };

  const onClickPlay = () => {
    if (!checkPlayerErrors(players)) {
      if (!getOverAge())
        setIsAgeDialogOpen(true);
      else
        onStartGame();
    }
  };

  const onShowErrorDialog = (error) => {
    switch (error) {
      case 'PERS':
        setErrorText(t('AddPlayers.dialog.persError'));
        break;
      case 'PARSE':
        setErrorText(t('AddPlayers.dialog.parseError'));
        break;
      case 'EQU':
        setErrorText(t('AddPlayers.dialog.equError'));
        break;
      default:
        setErrorText('Error');
        break;
    }
    setIsErrorDialogOpen(true);
  };

  const onStartGame = () => {
    setIsAgeDialogOpen(false);
    saveOverAge();

    let filteredPlayers = players.filter(p => p !== '');

    createNewGameAction(filteredPlayers);
    updateCurrentChallenge();
    history.push(Routes.Tutorial);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const checkPlayerErrors = (players: Array<string>) => {
    if (players[0].length > 0 && players[1].length > 0 && players[2].length > 0 && players[3].length > 0) {
      for (let i = 0; i < players.length; ++i) {
        let p = players[i];

        // Check that the name is not empty
        if (p.replace(/\s/g, '').length === 0) {
          onShowErrorDialog('PARSE');
          return true;
        }

        // Check that the name is unique
        for (let j = 0; j < players.length; ++j) {
          if (p.toLowerCase() === players[j].toLowerCase() && i !== j) {
            onShowErrorDialog('EQU');
            return true;
          }
        }
      }
      return false;
    } else {
      onShowErrorDialog('PERS')
      return true;
    }
  };

  return (
      <main>
        <Grid container direction='column' justify='center' alignItems='center'>

          {/*Title*/}
          <Typography align='center' variant='h5' className='mt-5 mb-2 whiteColor'>
            {t('AddPlayers.title')}
          </Typography>

          {/*Card to input players*/}
          <Card className='p-4 spaceBot'>
            {/*Add players*/}
            {players && players.map((elem, pos) =>
                <div key={pos}>
                  <Grow in={true} timeout={pos === 3 ? 1000 : 500}>
                    <div>
                      {pos <= 3 && <TextField
                          id={pos.toString()}
                          variant='outlined'
                          className='nameInput'
                          onChange={handleWritePlayer}
                          placeholder={t('AddPlayers.player') + ' ' + (pos + 1)}
                          InputProps={{
                            endAdornment: (
                                <div style={{height: 20, width: 31}}/>
                            ),
                          }}
                      />}
                      {pos > 3 && <TextField
                          id={pos.toString()}
                          variant='outlined'
                          className='nameInput'
                          onChange={handleWritePlayer}
                          placeholder={t('AddPlayers.player') + ' ' + (pos + 1)}
                          InputProps={{
                            endAdornment: (
                                <IconButton aria-label="Clear" className='pl-0'
                                            onClick={() => deletePlayerField(pos)}>
                                  <Clear fontSize="small"/>
                                </IconButton>
                            ),
                          }}
                      />}
                    </div>
                  </Grow>
                </div>
            )}

            {/*New player button*/}
            <Grow in={true} timeout={1500}>
              <div className="newPlayer">
                {players && players.length < MAX_PLAYERS &&
                <Button variant='outlined' fullWidth={true} color='secondary'
                        onClick={addPlayerField}>{t('AddPlayers.addPlayer')}</Button>}
                {players && players.length >= MAX_PLAYERS &&
                <Typography variant='overline' align='center'>{t('AddPlayers.maxPlayers', {number: MAX_PLAYERS})}</Typography>}
              </div>
            </Grow>
          </Card>

          {/*Language buttons*/}
          <div className='mb-5'>
            <IconButton onClick={() => changeLanguage('en')}><img src={engIcon} alt=''
                                                                  className='icon-dialog'/></IconButton>
            <IconButton onClick={() => changeLanguage('es')}><img src={espIcon} alt=''
                                                                  className='icon-dialog'/></IconButton>
          </div>

        </Grid>

        {/*Play Button*/}
        {width < 576 ?
            <AppBar position='fixed' className='placeBottom'>
              <Button
                  onClick={onClickPlay}
                  className='p-3'
                  variant='outlined'
                  fullWidth={true}
                  color='secondary'>
                {t('AddPlayers.btnNext')}
              </Button>
            </AppBar>
            :
            <Button
                onClick={onClickPlay}
                color='secondary'
                className='p-3 mb-5 playButtonDesktop'
                variant='contained'>
              {t('AddPlayers.btnNext')}
            </Button>
        }

        {/*Error Dialog*/}
        <Dialog
            open={isErrorDialogOpen}
            onClose={() => setIsErrorDialogOpen(false)}
            aria-labelledby='dialogTitle'
            aria-describedby='dialogDescr'>

          <DialogTitle id='dialogTitle'>{t('AddPlayers.dialog.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='dialogDesc'>
              {errorText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsErrorDialogOpen(false)} color="primary">
              {t('AddPlayers.dialog.button')}
            </Button>
          </DialogActions>
        </Dialog>

        {/*+18 dialog*/}
        <Dialog
            open={isAgeDialogOpen}
            onClose={() => setIsAgeDialogOpen(false)}>

          <DialogTitle>{t('AddPlayers.age.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('AddPlayers.age.content.part1')}
              <br/>co
              {t('AddPlayers.age.content.part2')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onStartGame} color="primary">
              {t('AddPlayers.age.button')}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
  )
};

export default AddPlayers;