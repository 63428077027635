import React, {FC} from "react";
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {Routes} from "../../Navigation/Routes";
import {Text} from "../../Components/Text/TextComponent";
import {TextButton} from "../../Components/Buttons/TextButton";
import {GlobalStyles} from "../../Theme/GlobalStyles";
import {Container, Image} from "react-bootstrap";
import {styles} from "./HomeStyles";
import {COVID} from "../../Config/Constants";

const homeDrawing = require('../../Images/ilustrations/home-table.png');

export const HomeScreen: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();

  return (
      <div style={styles.back}>
        <Container style={GlobalStyles.containerPrimary}>
          <Image src={homeDrawing} fluid style={styles.image}/>
          <Text text={t('Home.title')} hp/>
          <div style={styles.box}>
            <Text text={t('Home.presentation')}/>
            <Text text={t('Home.presentation2')}/>
          </div>

          {COVID && <div style={styles.boxCovid}>
            <Text text={t('Home.covidTitle')} h5 bold white/>
            <Text text={t('Home.covid')} white/>
            <Text text={t('Home.covidPlay')} bold white/>
          </div>}

          <TextButton text={t('Home.play')} onPress={() => history.push(Routes.AddPlayers)} big white full textStyle={styles.button}/>
          <div style={styles.footer}>
            <Text text={t('Home.footer')} bold white/>
          </div>
        </Container>
      </div>
  )
};

export default HomeScreen;