import React from 'react';
import ReactDOM from 'react-dom';
import './Theme/rootstyles.css';
import 'typeface-roboto';
import * as serviceWorker from './Config/serviceWorker'
import App from './App';
import './Config/i18n';

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);

serviceWorker.register();