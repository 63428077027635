import {GameStore} from "./Store";
import {BattleMode, GameState, Player, Power} from "./Types";

export const selectPlayers = (): Player[] => {
  return GameStore.useState<Player[]>(s => s.players);
};

export const selectLastInteractedPlayer = (): Player => {
  return GameStore.useState<Player>(s => s.lastInteractedPlayer);
};

export const selectBattleMode = (): BattleMode => {
  return GameStore.useState<BattleMode>(s => s.gameMode);
};

export const selectWholeState = (): GameState => {
  return GameStore.useState<GameState>(s => s);
};

export const selectCurrentChallenge = (): string => {
  return GameStore.useState<string>(s => s.currentChallenge);
};

export const selectCurrentOpenPlayer = (): Player => {
  return GameStore.useState<Player>(s => s.openPlayer);
};

export const selectLastPowerOwner = (): Player | undefined => {
  return GameStore.useState<Player>(s => s.lastPowerOwner);
};

export const selectLastPower = (): Power => {
  return GameStore.useState<Power>(s => s.lastPower);
};

export const selectDevelopmentMode = (): boolean => {
  return GameStore.useState<boolean>(s => s.development);
};

export const selectGameID = (): string => {
  return GameStore.useState<string>(s => s.gameID);
};