import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Card from '@material-ui/core/Card'
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ReactHtmlParser from "react-html-parser";
import Button from "@material-ui/core/Button";

const About: FC = (props: any) => {
  const {t} = useTranslation();

  /**
   * Navigates to PayPal.
   * */
  const navToDonate = () => {
    window.open('https://paypal.me/drunkevolution', '_blank');
  };

  return (
      <div className='container'>
        <Card className='mt-5'>
          <CardContent>
            {/*Title*/}
            <Typography variant='h5' className='text-center'>
              {t('About.title1')}
            </Typography>

            <Typography variant='body1' className='mt-3'>
              {ReactHtmlParser(t('About.text1'))}
            </Typography>

            <div className="text-center">
              <a href="mailto:imdrunk@drunkevolution.com" target='_blank' rel="noopener noreferrer">
                <Button variant='contained'
                        className='text-center mt-4 mb-3 playButton'>
                  <Typography variant='h6'>{t('About.contact')}</Typography>
                </Button>
              </a>
            </div>

            {/*Title*/}
            <Typography variant='h5' className='text-center bt-5'>
              {t('About.title2')}
            </Typography>

            <Typography variant='body1' className='mt-3'>
              {ReactHtmlParser(t('About.text2'))}
            </Typography>

            <div className="text-center">
              <Button color='secondary'
                      variant='contained'
                      className='mt-4 mb-3 playButton'
                      onClick={navToDonate}>
                <Typography variant='h5'>{t('About.donate')}</Typography>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
  )
};

export default About;