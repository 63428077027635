import React, {CSSProperties, FC} from "react";
import {Theme} from "../../Theme/Theme";

type Props = {
  // Behaviour
  onPress: () => void
  disabled?: boolean

  // Colors
  transparent?: boolean
  light?: boolean

  // Borders
  noBorder?: boolean
  whiteBorder?: boolean

  // Shadow
  flat?: boolean

  // Spacing
  noMargin?: boolean
  noMarginTop?: boolean
  noMarginBottom?: boolean
  fullWidth?: boolean
  center?: boolean

  children: any
  style?: CSSProperties
}

export const Button: FC<Props> = (props: Props) => {

  const getBackgroundColor = () => {
    if (props.light) return Theme.secondaryLight;
    else if (props.transparent) return Theme.transparent;

    else return Theme.secondaryMedium;
  };

  const getMarginBottom = () => {
    if (props.noMarginBottom || props.noMargin) return 0;

    return Theme.spaceBigXL
  };

  const getMarginTop = () => {
    if (props.noMarginTop || props.noMargin) return 0;

    return Theme.spaceSmall
  };

  const getAlignment = () => {
    if (props.center) return 'center';
    else if (props.fullWidth) return 'stretch';

    return 'flex-start';
  };

  const getBorderColor = () => {
    if (props.disabled) return Theme.secondaryLight;
    else if (props.whiteBorder) return Theme.white;

    return Theme.primaryMedium;
  };

  const getBoxShadow = () => {
    if (props.flat || props.transparent) return 'unset';

    return '8px 8px 15px rgba(0, 0, 0, 0.25)';
  };

  const customStyles: CSSProperties = {
    backgroundColor: getBackgroundColor(),
    marginTop: getMarginTop(),
    marginBottom: getMarginBottom(),
    alignSelf: getAlignment(),
    borderRadius: Theme.buttonRadius,
    borderWidth: props.transparent ? 0 : Theme.buttonBorder,
    borderColor: getBorderColor(),
    borderStyle: "solid",
    boxShadow: getBoxShadow(),
    paddingLeft: Theme.paddingMedium,
    paddingRight: Theme.paddingMedium,
    display: "block",
    width: props.fullWidth ? '100%' : 'auto',
    ...props.style
  };

  return (
      <button
          style={customStyles}
          onClick={props.onPress}
          disabled={props.disabled}
          className={"btn"}
      >
        {props.children}
      </button>
  )
};