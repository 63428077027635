import {GlobalStyles, StyleSheet} from "../../../Theme/GlobalStyles";
import {Theme} from "../../../Theme/Theme";
import {PHONE_WIDTH} from "../../../Config/Constants";

// @ts-ignore
const isPhone = window.innerWidth <= PHONE_WIDTH;

export const styles: StyleSheet = {
  container: {
    ...GlobalStyles.shadowBig,
    position: "fixed",
    width: isPhone ? '100%' : '75%',
    bottom: isPhone ? 0 : Theme.spaceBig,
    backgroundColor: Theme.secondaryMedium,
    display: "flex",
    justifyContent: "space-around", // TODO: Change to "space-between"
    alignItems: "center",
    padding: Theme.spaceBig,
    borderRadius: isPhone ? 0 : Theme.buttonRadius,
  }
};