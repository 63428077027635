import {Store} from "pullstate";
import {BattleMode, GameState} from "./Types";
import {createUUID} from "../../Utils/GeneralUtils";
import {getWholeState} from "../../API/Storage";

const getInitialPlayerState = () => {
  if (getWholeState() && getWholeState().players)
    return getWholeState().players.map(p => {
      let player = p;
      player.character.lastPowerDate = new Date(p.character.lastPowerDate);
      return  player;
    });
  return [];
}

export const GameStore = new Store<GameState>({
  etilicBattle: getWholeState() ? getWholeState().etilicBattle : [],
  nextBattleChallengeNumber: getWholeState() ? getWholeState().nextBattleChallengeNumber : 0,
  lastInteractedPlayer: getWholeState() ? getWholeState().lastInteractedPlayer : undefined,
  players: getInitialPlayerState(),
  gameMode: getWholeState() ? getWholeState().gameMode : BattleMode.Hot,
  lastPower: getWholeState() ? getWholeState().lastPower : undefined,
  currentChallenge: getWholeState() ? getWholeState().currentChallenge : '',
  openPlayer: getWholeState() ? getWholeState().openPlayer : undefined,
  lastPowerOwner: getWholeState() ? getWholeState().lastPowerOwner : undefined,
  development: getWholeState() ? getWholeState().development : false,
  gameID: getWholeState() ? getWholeState().gameID : createUUID(),
  lastChallengeDate: getWholeState() ? new Date(getWholeState().lastChallengeDate) : undefined,
  lastModeChangeDate: getWholeState() ? new Date(getWholeState().lastModeChangeDate) : new Date()
});