import React, {CSSProperties, FC} from "react";
import {TFunctionResult} from "i18next";
import {Theme} from "../../Theme/Theme";

type Props = {
  text: string | number | TFunctionResult

  numberOfLines?: number

  // FontSize
  h1?: boolean
  h2?: boolean
  h3?: boolean
  h4?: boolean
  h5?: boolean
  hp?: boolean
  small?: boolean

  // Color
  primary?: boolean
  secondary?: boolean
  dark?: boolean
  light?: boolean
  gray?: boolean
  white?: boolean
  disabled?: boolean

  // Spacing
  noMargin?: boolean
  margin?: boolean
  marginMedium?: boolean
  marginBig?: boolean
  center?: boolean
  noMarginTop?: boolean
  noMarginBottom?: boolean

  // Weight
  bold?: boolean
  titleFont?: boolean

  // Other styles
  style?: CSSProperties

  // Link text
  onPress?: () => void
}

export const Text: FC<Props> = (props: Props) => {
  const getFontSize = () => {
    if (props.small) return Theme.fontSizeMedium
    else if (props.h1 || props.hp) return Theme.fontSizeH1;
    else if (props.h2) return Theme.fontSizeH2;
    else if (props.h3) return Theme.fontSizeH3;
    else if (props.h4) return Theme.fontSizeH4;
    else if (props.h5) return Theme.fontSizeH5;

    else return Theme.fontSizeMedium;
  };

  const getColor = () => {
    if (props.hp) return Theme.white;
    else if (props.secondary) return Theme.secondaryMedium;
    else if (props.dark) return Theme.primaryDark;
    else if (props.light) return Theme.primaryLight;
    else if (props.primary) return Theme.primaryMedium;
    else if (props.gray || props.disabled) return Theme.secondaryLight;
    else if (props.white) return Theme.white;

    else return Theme.secondaryDark;
  };

  const getMargin = () => {
    if (props.noMargin) return 0;
    else if (props.marginMedium || props.h2) return Theme.spaceMedium;
    else if (props.marginBig || props.h1) return Theme.spaceBig;

    else return Theme.spaceSmall;
  };

  const getFontWeight = () => {
    if (props.bold) return "bold";

    else return "normal"
  };

  const getJustifyContent = () => {
    if (props.center === undefined) return "center";
    else return "flex-start"
  };

  const getTextAlign = () => {
    if (props.center === undefined) return "center";
    else return "left"
  };

  const getMarginTop = () => {
    if (props.noMarginTop) return 0;
    else return getMargin();
  };

  const getMarginBottom = () => {
    if (props.noMarginBottom) return 0;
    else return getMargin();
  };

  const getFontFamily = () => {
    if (props.titleFont || props.h1 || props.h2 || props.h3 || props.hp) return Theme.fontFamilyTitle;

    return Theme.fontFamilyNormal;
  }

  const getTextShadow = () => {
    if (props.hp) return '4px 4px black';

    return 'unset';
  }

  const style: CSSProperties = {
    fontSize: getFontSize(),
    color: getColor(),
    marginTop: getMarginTop(),
    marginBottom: getMarginBottom(),
    fontWeight: getFontWeight(),
    justifyContent: getJustifyContent(),
    textAlign: getTextAlign(),
    fontFamily: getFontFamily(),
    textShadow: getTextShadow(),
    ...props.style,
  };

  return (
      <p style={style}>
        {props.text}
      </p>
  )
};