import {CSSProperties} from "react";
import {Theme} from "./Theme";

export interface StyleSheet {[key: string]: CSSProperties}

export const GlobalStyles: StyleSheet = {
  centeredContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  containerPrimary: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 'initial',
    width: '100%',
    backgroundColor: Theme.primaryMedium,
    paddingTop: "calc(3vmin + 64px)",
    paddingLeft: Theme.paddingBig,
    paddingRight: Theme.paddingBig,
  },
  containerDark: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 'inherit',
    backgroundColor: Theme.background,
    paddingLeft: Theme.paddingBig,
    paddingRight: Theme.paddingBig,
  },
  shadowSmall: {
    boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.34)",
  },
  shadowBig: {
    boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.34)",
  },
  informationBox: {
    backgroundColor: Theme.primaryLighter,
    borderColor: Theme.secondaryDark,
    borderRadius: Theme.buttonRadius,
    borderWidth: Theme.buttonBorder,
    borderStyle: "solid",
    marginTop: Theme.spaceMedium,
    marginBottom: Theme.spaceBigXL,
    padding: Theme.spaceSmall,
    paddingLeft: Theme.spaceMedium,
    paddingRight: Theme.spaceMedium,
  }
}