import React, {FC, useState} from 'react';
import './AddChallenge.css';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {Button} from "@material-ui/core";
import {createNewBattleChallenge} from '../../../API/Cloud';
import {Challenge} from "../../../State/GameState/Types";
import {Languages} from "../../../API/Types";


const AddChallenge: FC = (props: any) => {
  const [challenge, setChallenge] = useState('');
  const [language, setLanguage] = useState<Languages>();
  const [hot, setHot] = useState(true);
  const [success, setSuccess] = useState('');

  const isClickable = () => {
    return challenge !== '' && language;
  };

  const pushChallenge = async () => {
    const challengeObject: Challenge = {
      challenge: challenge,
      hot: hot
    };

    const res = await createNewBattleChallenge(challengeObject, language);
    if (res.error) {
      setSuccess('Ha habido un error. Habla con Mati y envíale captura de la consola.')
    } else {
      setSuccess(`El nuevo reto se ha subido correctamente. ${challenge}; ${hot ? 'Hot' : 'No hot'}; ${language}`);
      setChallenge('');
      setLanguage(undefined);
      setHot(true);
    }
  };

  return (
      <div>
        {/*Challenge*/}
        <TextField
            id='challenge'
            className='w-100'
            multiline
            rows='4'
            variant='outlined'
            label='Descripción del challenge'
            onChange={ev => setChallenge(ev.target["value"])}
        />

        {/*Hot or not*/}
        <FormControlLabel
            control={
              <Switch
                  id='hot'
                  checked={hot}
                  onChange={ev => setHot(ev.target["checked"])}
              />}
            label={hot ? 'Modo hot' : 'Modo NO hot'}
        />

        {/*Language*/}
        <RadioGroup
            id='language'
            value={language}
            onChange={(ev: any) => setLanguage(Languages[ev.target.value])}>
          <FormControlLabel value="es" id="language" control={<Radio id="language"/>}
                            label="Español"/>
          <FormControlLabel value="en" id="language" control={<Radio id="language"/>}
                            label="Inglés"/>
          <FormControlLabel value="fr" id="language" control={<Radio id="language"/>}
                            label="Francés"/>
          <FormControlLabel value="it" id="language" control={<Radio id="language"/>}
                            label="Italiano"/>
        </RadioGroup>

        {/*Upload*/}
        <Button onClick={pushChallenge} disabled={!isClickable()}>
          Guardar reto
        </Button>
        <Typography variant="caption" style={{color: '#18B618'}}><b>{success}</b></Typography>
      </div>
  )
};

// @ts-ignore
export default AddChallenge;