import * as React from "react";
import {CSSProperties, FC} from "react";
import {Button} from "./Button";
import {Text} from "../Text/TextComponent";

type Props = {
  onPress: () => void
  text: string

  // Props
  disabled?: boolean
  transparent?: boolean
  white?: boolean
  orange?: boolean
  center?: boolean
  full?: boolean

  // Size
  big?: boolean
  medium?: boolean
  small?: boolean

  buttonStyle?: CSSProperties
  textStyle?: CSSProperties
};

export const TextButton: FC<Props> = (props: Props) => {
  return (
      <Button
          onPress={props.onPress}
          transparent={props.transparent}
          whiteBorder={props.white}
          disabled={props.disabled}
          center={props.center}
          fullWidth={props.full}
          style={props.buttonStyle}
      >
        <Text
            text={props.text.toUpperCase()}
            primary={props.orange}
            white={!props.orange}
            disabled={props.disabled}
            h3={props.big}
            h4={props.medium}
            h5={!props.big || !props.small}
            style={props.textStyle}
        />
      </Button>
  )
}