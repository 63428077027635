import {GlobalStyles, StyleSheet} from "../../../Theme/GlobalStyles";
import {Theme} from "../../../Theme/Theme";

export const styles: StyleSheet = {
  desktopParent: {
    flexDirection: "row",
    position: "relative",
    width: '100%',
    marginBottom: Theme.spaceBigXL
  },
  phoneBox: {
    ...GlobalStyles.shadowSmall,
    ...GlobalStyles.informationBox,
    borderColor: Theme.primaryMedium,
    backgroundColor: '#FFD39D'
  },
  desktopBox: {
    ...GlobalStyles.shadowSmall,
    ...GlobalStyles.informationBox,
    display: "inline-flex",
    height: "100%",
    margin: 0,
    width: '70%',
    position: "absolute",
    borderColor: Theme.primaryMedium,
    backgroundColor: '#FFD39D',
    justifyContent: "center",
    alignItems: "center"
  },
  phoneStep: {
    display: "inline-flex",
    lineHeight: 0.8
  },
  phoneTitle: {
    display: "inline-flex",
    marginLeft: Theme.spaceMedium,
    lineHeight: 0.8
  },
  phoneContainer: {
    flexDirection: "row"
  },
  phoneParent: {

  },
  desktopContainer: {
    ...GlobalStyles.shadowSmall,
    ...GlobalStyles.informationBox,
    display: "inline-flex",
    flexDirection: "column",
    flex: "none",
    width: '30%',
    margin: 0,
    marginLeft: Theme.spaceBig,
    left: 10,
    position: "relative",
    borderColor: Theme.primaryMedium,
    backgroundColor: Theme.secondaryMedium,
  },
  desktopFontTitle: {
    lineHeight: 0.9,
    fontSize: Theme.fontSizeH4
  },
  desktopFontSubtitle: {
    lineHeight: 0.9,
    fontSize: 18
  },
  descriptionText: {
    fontSize: 16
  }
}