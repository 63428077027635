import {GameStore} from "./Store";
import {printStateSubscription, saveUpdatedState, updateChallengeSubscription} from "./Reactions";

export const subscribeAll = () => {
  GameStore.createReaction(s => s.nextBattleChallengeNumber, updateChallengeSubscription);
  GameStore.createReaction(s => s.gameMode, updateChallengeSubscription);
  GameStore.createReaction(s => s, printStateSubscription);
  GameStore.createReaction(s => s, saveUpdatedState);
}

