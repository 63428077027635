import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useWindowWidth} from "@react-hook/window-size";
import {secondsFromDate} from "../../Utils/GeneralUtils";
import {Statistics} from "../../API/Statistics";
import {selectDevelopmentMode, selectGameID} from "../../State/GameState/Selectors";
import {PHONE_WIDTH} from "../../Config/Constants";
import {Container} from "react-bootstrap";
import {GlobalStyles} from "../../Theme/GlobalStyles";
import {TextButton} from "../../Components/Buttons/TextButton";
import {Routes} from "../../Navigation/Routes";
import {TutorialStep} from "../../Components/Custom/TutorialStep/TutorialStepComponent";

type Props = {
  onlyTutorial?: boolean
}

const TutorialScreen: FC<Props> = ({onlyTutorial}) => {
  const {t} = useTranslation();
  const width = useWindowWidth();
  const history = useHistory();

  const startTime = new Date();

  const developmentMode = selectDevelopmentMode();
  const gameID = selectGameID();

  const logStatistics = () => {
    const endTime = new Date();
    const elapsedTime = secondsFromDate(startTime, endTime);
    Statistics.logReadTutorial(elapsedTime, developmentMode, gameID);
  };

  const navToNextStep = () => {
    logStatistics();
    history.push({pathname: onlyTutorial ? Routes.Leaderboard : Routes.Characters})
  };

  return (
      <Container style={{...GlobalStyles.containerDark, marginTop: 0}}>
        <TutorialStep number={1} title={t('Tutorial.steps.1.title')} description={t('Tutorial.steps.1.description')} phone={width <= PHONE_WIDTH}/>
        <TutorialStep number={2} title={t('Tutorial.steps.2.title')} description={t('Tutorial.steps.2.description')} phone={width <= PHONE_WIDTH}/>
        <TutorialStep number={3} title={t('Tutorial.steps.3.title')} description={t('Tutorial.steps.3.description')} phone={width <= PHONE_WIDTH}/>
        <TutorialStep number={4} title={t('Tutorial.steps.4.title')} description={t('Tutorial.steps.4.description')} phone={width <= PHONE_WIDTH}/>

        <TextButton onPress={navToNextStep} text={onlyTutorial ? t('Tutorial.buttons.back') : t('Tutorial.buttons.next')} center big/>
      </Container>
  )
};

export default TutorialScreen;
