import {StyleSheet} from "../../../Theme/GlobalStyles";
import {Theme} from "../../../Theme/Theme";

const CARD_SIZE = 180;

export const styles: StyleSheet = {
  card: {
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.25)",
    backgroundColor: Theme.primaryLighter,
    borderRadius: Theme.buttonRadius,
    width: '85%',
    padding: Theme.spaceSmall,
    textAlign: "center",
    marginTop: Theme.spaceBigXL,
    marginLeft: Theme.spaceBig,
    marginRight: Theme.spaceBig,
    resize: "both",
    cursor: "pointer",
  },
  singleCard: {
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.25)",
    backgroundColor: Theme.primaryLighter,
    borderRadius: Theme.buttonRadius,
    width: CARD_SIZE,
    height: CARD_SIZE,
    padding: Theme.spaceSmall,
    textAlign: "center",
    marginTop: Theme.spaceBigXL,
    marginLeft: "auto",
    marginRight: "auto",
    resize: "both",
  },
  name: {
    fontSize: Theme.fontSizeH5,
    width: '100%',
    height: 35,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  avatar: {
    width: 96,
    height: 96
  }
};