import React, {FC, useState} from "react";
import {styles} from "./TabNavigatorStyles";
import {useHistory} from "react-router-dom";
import {Routes} from "../../../Navigation/Routes";
import {NavigationButton} from "../../Buttons/NavigationButton";

type Selected = "leaderboard" | "battle" | "players";
type Props = {};

export const TabNavigator: FC<Props> = (props: Props) => {
  const history = useHistory();

  const path = history.location.pathname;
  const initialSelected: Selected =
      path === Routes.EtilicBattle ?
          "battle" : path === Routes.Leaderboard ?
          "leaderboard" : "players";

  const [selectedButton, setSelectedButton] = useState<Selected>(initialSelected);

  return (
      <div style={styles.container}>
        {/*<NavigationButton*/}
        {/*    onSelect={() => {*/}
        {/*      history.replace(Routes.AddPlayers)*/}
        {/*      setSelectedButton("players")*/}
        {/*    }}*/}
        {/*    selected={selectedButton === "players"}*/}
        {/*    type="players"*/}
        {/*/>*/}
        <NavigationButton
            onSelect={() => {
              history.replace(Routes.Leaderboard)
              setSelectedButton("leaderboard")
            }}
            selected={selectedButton === "leaderboard"}
            type="leaderboard"
        />
        <NavigationButton
            onSelect={() => {
              history.replace(Routes.EtilicBattle, {fromNavigator: true})
              setSelectedButton("battle")
            }}
            selected={selectedButton === "battle"}
            type="battle"
        />
      </div>
  )
};