import React, {FC} from "react";
import {Button} from "./Button";
import {Text} from "../Text/TextComponent";
import {useTranslation} from "react-i18next";
import {styles} from "./ButtonsStyles";
import {useWindowWidth} from "@react-hook/window-size/dist/es";
import {PHONE_WIDTH} from "../../Config/Constants";

const battleUn = require('../../Images/icons/battle-unselected.png');
const battle = require('../../Images/icons/battle-selected.png');
const playersUn = require('../../Images/icons/addRemove-unselected.png');
const players = require('../../Images/icons/addRemove-selected.png');
const leaderboardUn = require('../../Images/icons/leaderboard-unselected.png');
const leaderboard = require('../../Images/icons/leaderboard-selected.png');

type Props = {
  selected: boolean
  onSelect: () => void
  type: "leaderboard" | "battle" | "players"
};

export const NavigationButton: FC<Props> = ({onSelect, selected, type}) => {
  const {t} = useTranslation();
  const width = useWindowWidth();

  const getImage = () => {
    switch (type) {
      case "battle":
        return selected ? battle : battleUn;
      case "leaderboard":
        return selected ? leaderboard : leaderboardUn;
      case "players":
        return selected ? players : playersUn;
    }
  }

  return (
      <Button onPress={onSelect} transparent noMargin style={styles.navButton}>
        <img src={getImage()} alt="icon" style={styles.navIcon}/>
        {width >= PHONE_WIDTH &&
          <Text text={t(`Navigation.${type}`)} bold={selected} style={styles.navText} white h5/>
        }
      </Button>
  )
};